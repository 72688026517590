import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import PostCard from "../../components/PostCard"

// Styles
import "../../styles/traefik-ambassador-program-thank-you.scss"

// Images
import ambassadorLogo from "../../images/ambassador/ambassador-logo.svg"

const AmbassadorThankYouPage = ({ data, location }) => {
  const relatedResources = data.relatedResources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />
      <div className="ambassador-thank-you-page">
        <main className="page-content-section">
          <div className="wrapper-1200">
            <img src={ambassadorLogo} alt="Thank you for your submission!" />
            <h1>Thank you for your submission!</h1>
            <p className="sub-title--large">We'll be in touch soon</p>
          </div>
        </main>

        {relatedResources.length > 0 && (
          <section className="ambassador-thank-you-resources page-content-section">
            <div className="wrapper-1200">
              <div className="page-content-section__header">
                <h2>Discover Traefik</h2>
              </div>
              <div className="related-resource-feed">
                {relatedResources.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    currentPage: ghostPage(slug: { eq: "traefik-ambassador-program" }) {
      ...PageMetaFields
    }

    relatedResources: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-traefik-related-resource" } } }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`

export default AmbassadorThankYouPage
