import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./PostCard.scss"

type Props = {
  post: {
    feature_image: string
    featureImageSharp: {
      publicURL: string
    }
    title: string
    slug: string
    tags: Array<Tag>
    published_at_pretty: string
    excerpt: string
    primary_tag: {
      slug: string
    }
    primary_author: {
      profile_image: string
      profileImageSharp: {
        publicURL: string
      }
      name: string
    }
  }
}

type Tag = {
  name: string
}

const PostCard: React.FC<Props> = ({ post }) => {
  const authorProfileImage = getImage(post?.primary_author?.profileImageSharp)
  const featuredImage = getImage(post.featureImageSharp)

  return (
    <div
      className={
        "post-card" + (featuredImage ? "" : " no-image") + " post-feed__card"
      }
    >
      <Link
        to={`/${post.primary_tag?.slug}/${post.slug}/`}
        className="post-card__wrapper"
      >
        {featuredImage && (
          <GatsbyImage
            image={featuredImage}
            alt={post.title}
            className="post-card__image"
            imgStyle={{
              borderRadius: "8px",
            }}
          />
        )}
        <div className="post-card__content">
          <h2 className="post-card__title">{post.title}</h2>
          <div className="post-card__meta">
            <div className="post-card__author">
              <div>
                {authorProfileImage ? (
                  <GatsbyImage
                    image={authorProfileImage}
                    alt={post.primary_author.name}
                    className="avatar"
                    imgStyle={{
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  post.primary_author?.profile_image && (
                    <img
                      src={post.primary_author?.profile_image}
                      className="avatar"
                    />
                  )
                )}
                <div className="name">{post.primary_author.name}</div>
              </div>
            </div>
            <div className="middot">&middot;</div>
            <div className="post-card__tag">
              {post.tags[1] && (
                <>
                  <div className="text">{post.tags[1].name}</div>
                  <div className="middot">&middot;</div>
                </>
              )}
            </div>
            <div className="post-card__date">{post.published_at_pretty}</div>
          </div>
          <p className="post-card__excerpt">{post.excerpt}</p>
          <div className="link--with-arrow">Read more</div>
        </div>
      </Link>
    </div>
  )
}

export default PostCard
